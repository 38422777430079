<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/weapphome/hotActivity' }">发现页活动列表</el-breadcrumb-item>
        <el-breadcrumb-item>添加</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--内容-->
    <div class="page-content-x">
      <el-row>
        <el-col :span="17">
          <el-form size="medium" v-loading.fullscreen.lock="loading" ref="form" :label-width="this.env.label_width">
            <el-col :span="24">
              <el-form-item label="活动名称">
                <el-input v-model="form.data_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="上传背景图">
                <Qnupload v-model="form.data_bg_img_url" :sum="1" :fixedNumber="[1, 1]" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="参与活动门店">
                <el-select v-model="store_uuid_all" remote multiple filterable placeholder="请选择门店" style="width: 100%"
                  :remote-method="remoteMethod">
                  <el-option v-for="(item) in store_list" :key="item.store_uuid" :label="item.store_name"
                    :value="item.store_uuid">
                    <span style="color: #8492a6; font-size: 13px">
                      【{{ item.company_name }}】
                    </span>
                    <span>{{ item.store_name }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="运动品类">
                <MotionCategorySelect :motionCategoryList.sync="motion_category_two" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="运营标签">
                <div>
                  <el-select v-model="operate_tag" multiple filterable placeholder="请选择运营标签" remote
                    :remote-method="get_store_tag" :loading="select_loading" style="width: 100%">
                    <el-option v-for="(item, key) in store_tag_list" :key="key" :label="item.name" :value="item.id">
                      <span style="color: #8492a6; font-size: 13px">
                        【{{ item.name }}】
                      </span>
                      <span>{{ item.description }}</span>
                    </el-option>
                  </el-select>
                </div>
                <div class="form-tag_colour-div">
                  <el-tag v-for="(item, key) in this.chooseTag" :key="key" class="form-tag_colour-tag" effect="dark"
                    closable size="small" @close="tagClose(item)" :color="item.tag_colour">
                    {{ item.tag_name }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item>
                <el-button v-if="is_auth('weapphome.bannerpage.isadd')" size="medium" type="primary" @click="save">保存
                </el-button>
                <el-button size="medium" @click="isreturn">返回</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Qnupload from "@/components/Qnupload";
import MotionCategorySelect from "@/components/MotionCategorySelect";
export default {
  components: {
    Qnupload,
    MotionCategorySelect,
  },
  data() {
    return {
      id: 0,
      loading: false,
      info: null,
      select_loading: false,
      store_list: [],
      store_uuid_all: [],
      operate_tag: [],
      chooseTag: [],
      tag_uuid_all: [],
      store_tag_list: [], // 门店标签
      form: {
        data_bg_img_url: [],
      },
      motion_category_two: [],
    };
  },
  created() {
    this.get_store_list(null); // 获取可选门店
    this.get_store_tag();
    this.init();
  },
  methods: {
    init() {
      const { id } = this.$route.query;
      if (id) {
        this.id = id;
        this.loading = true;
        this.getInfo();
      }
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 获取详情
    getInfo() {
      const { id } = this;
      const postData = {
        api_name: "weapphome.bannerpage.getinfo",
        token: this.Tool.get_l_cache("token"),
        id,
      };
      this.Tool.post_data("oss", postData, (json) => {
        // this.select_loading = false;
        this.loading = false;
        if (json.code === 0) {
          this.form["data_bg_img_url"] = json.data.data_bg_img_url;
          this.form["data_name"] = json.data.data_name;
          this.motion_category_two = [
            ...json.data.data_info.motion_category_two,
          ];
          this.store_uuid_all = json.data.data_info.store_uuid_all;
          this.operate_tag = json.data.data_info.operate_tag.map(
            (item) => item.id
          );
          this.chooseTag = json.data.data_info.tag_all
            ? json.data.data_info.tag_all
            : [];
        } else {
          // this.store_list = []
        }
      });
    },
    get_store_list(key) {
      let postdata = {
        api_name: "store.store.getusablestore",
        token: this.Tool.get_l_cache("token"),
        key,
      };
      this.select_loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.select_loading = false;
        if (json.code === 0) {
          this.store_list = json.data;
        } else {
          // this.store_list = []
        }
      });
    },
    // 课程门店标签
    get_store_tag() {
      let postdata = {
        api_name: "operate.tag.getlist",
        token: this.Tool.get_l_cache("token"),
        state: 1,
        page: 0,
        pagesize: 1000,
      };
      //   this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        // this.loading = false;
        if (json.code === 0) {
          this.store_tag_list = json.data.list;
        } else {
          this.store_tag_list = [];
          this.Tool.errormes(json);
        }
      });
    },
    // 判断标签是否存在
    tag_exist(tag_name, key) {
      if (this.form[key].length > 0) {
        for (let v of this.form[key]) {
          if (tag_name == v.tag_name) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },
    // 删除标签
    tagClose(tag) {
      this.chooseTag.splice(this.chooseTag.indexOf(tag), 1);
      this.topic_disabled = false;
    },
    // 门店搜索
    remoteMethod(e) {
      this.get_store_list(e);
    },
    save() {
      const { form, store_uuid_all, chooseTag, id, operate_tag } = this;
      let tag_uuid_all = [];
      if (chooseTag.length > 0) {
        chooseTag.map(
          (item) => (tag_uuid_all = tag_uuid_all.concat(item.tag_uuid))
        );
      }
      const postData = {
        ...form,
        api_name: "weapphome.bannerpage.isadd",
        token: this.Tool.get_l_cache("token"),
        source_tag: "store_list",
        data_info: {
          store_uuid_all,
          operate_tag,
          motion_category_two: this.motion_category_two.map((item) => item.id),
        },
      };

      if (id) {
        postData["id"] = id;
      }

      if (Array.isArray(postData.data_bg_img_url) && postData.data_bg_img_url.length == 0) {
        postData.data_bg_img_url = ''
      }
      this.Tool.post_data("oss", postData, (json) => {
        if (json.code === 0) {
          this.$message({
            message: "编辑成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.$router.push({ path: "/weapphome/hotActivity" });
            },
          });
        } else {
          this.$message.error(json.message);
        }
      });
    },
    isreturn() { },
  },
};
</script>
